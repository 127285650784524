import * as React from "react";
import Layout from "../components/layout";
import { Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const Contact = () => {
  return (
    <Layout pageTitle={"Contact"}>
      <div>
        コンタクトページです。
        <br />
        APIなしでの構成のフォームについては勉強中ですので、取り急ぎ以下Googleフォームのリンクを設置しております。
        <br />
        <Link
          isExternal
          href="https://docs.google.com/forms/d/1l1hmqylkapnKye60_WwRaHsaJrrzWb1OxARJcMNsNbA/edit"
        >
          こちら
          <ExternalLinkIcon mx="2px" />
        </Link>
      </div>
    </Layout>
  );
};
export default Contact;
